import type {Bugsnag} from '~/foundation/Bugsnag/Bugsnag';
import type {Monorail} from '~/foundation/Monorail/Monorail';
import {isoNavigator} from '~/utils/navigator';
import {isoWindow} from '~/utils/window';

import type {CredentialProvider, FederatedCredentials} from '../../../fedcm';

interface InitFedCMParams {
  mediation?: CredentialMediationRequirement;
  analyticsTraceId?: string;
  bugsnag?: Bugsnag;
  monorailTracker?: Monorail;
}

export const initFedCM = async (params: InitFedCMParams) => {
  const {
    mediation = 'optional',
    analyticsTraceId,
    bugsnag,
    monorailTracker,
  } = params;

  const providers = await getFedCMProviders(analyticsTraceId);

  return new Promise((resolve: (res: Response) => void, reject) => {
    try {
      // Browser does not support FedCM
      if (!('IdentityCredential' in isoWindow)) {
        return;
      }

      monorailTracker?.trackPageImpression({
        page: 'FEDCM_SHOWN',
      });

      // Make the request for credentials
      getFedCMCredentials(mediation, providers)
        .then((credential) => {
          // User has cancelled the fedCM prompt
          if (!credential) {
            monorailTracker?.trackUserAction({userAction: 'FEDCM_CANCELLED'});
            return;
          }
          // Open session on merchant.com
          submitTokenToServer(
            credential?.token,
            providers.state,
            resolve,
            reject,
            monorailTracker,
          );
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error: any) {
      bugsnag?.notify(error);

      reject(error);
    }
  });
};

function getFedCMCredentials(
  mediation: CredentialMediationRequirement,
  providers: CredentialProvider,
) {
  return (isoNavigator.credentials as FederatedCredentials).get({
    identity: {
      providers: [providers],
    },
    mediation,
  });
}

async function getFedCMProviders(
  analyticsTraceId?: string,
): Promise<CredentialProvider> {
  let url = '/services/login_with_shop/fedcm/provider';
  if (analyticsTraceId) {
    url += `?analyticsTraceId=${encodeURIComponent(analyticsTraceId)}`;
  }

  const response = await fetch(url, {
    method: 'GET',
  });

  const body = await response.json();

  return {
    configURL: body.configURL,
    clientId: body.clientId,
    nonce: body.nonce,
    state: body.state,
  };
}

function submitTokenToServer(
  token: string,
  state: string,
  resolve: (value: Response) => void,
  reject: (error?: any) => void,
  monorailTracker?: Monorail,
) {
  fetch('/services/login_with_shop/fedcm/callback', {
    method: 'POST',
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      raw_id_token: token,
      state,
    }).toString(),
  })
    .then((response) => {
      monorailTracker?.trackUserAction({userAction: 'FEDCM_COMPLETED'});
      resolve(response);
    })
    .catch((error) => reject(error));
}
