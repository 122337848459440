import {useCallback, useMemo} from 'preact/hooks';

import {CORE_AUTH_DOMAIN, PAY_AUTH_DOMAIN} from '~/constants/authorize';
import {useI18nContext} from '~/foundation/I18n/hooks';
import {useRootProvider} from '~/foundation/RootProvider/hooks';
import {isoWindow} from '~/utils/window';

import type {BuildAuthorizeUrlParams} from './types';
import {booleanQueryParam} from './utils';

export function useAuthorizeUrl({
  analyticsContext,
  avoidPayAltDomain = false,
  avoidSdkSession = false,
  disableSignUp = false,
  flow = 'default',
  flowVersion = 'unspecified',
  error,
  ...props
}: BuildAuthorizeUrlParams) {
  const {locale} = useI18nContext();
  const {instanceId} = useRootProvider();

  const buildAuthorizeUrl = useCallback((props: BuildAuthorizeUrlParams) => {
    if (!props.proxy) {
      if (props?.clientId === undefined) return '';

      const params = buildUrlSearchParams(props);

      /**
       * When `avoidPayAltDomain` is false, we need to route requests through the shop.app/pay/sdk-session endpoint.
       * The session endpoint will allow us to check for the presence of a Pay user session  and hoist it to the
       * "alt" pay.shopify.com/pay/sdk-authorize endpoint.
       */
      const path = props.avoidPayAltDomain
        ? '/pay/sdk-authorize'
        : '/pay/sdk-session';
      return `${PAY_AUTH_DOMAIN}${path}?${params}`;
    }

    const params = buildUrlSearchParams(props);

    return `${CORE_AUTH_DOMAIN}/services/login_with_shop/authorize?${params}`;
  }, []);

  const authorizeUrl = useMemo(() => {
    return buildAuthorizeUrl({
      analyticsContext,
      analyticsTraceId: instanceId,
      avoidPayAltDomain,
      avoidSdkSession,
      disableSignUp,
      flow,
      flowVersion,
      locale,
      error,
      ...props,
    });
  }, [
    analyticsContext,
    avoidPayAltDomain,
    avoidSdkSession,
    buildAuthorizeUrl,
    disableSignUp,
    error,
    flow,
    flowVersion,
    instanceId,
    locale,
    props,
  ]);

  return authorizeUrl;
}

function buildUrlSearchParams({
  analyticsContext,
  analyticsTraceId,
  apiKey,
  avoidSdkSession,
  checkoutRedirectUrl,
  checkoutToken,
  checkoutVersion,
  clientId,
  codeChallenge,
  codeChallengeMethod,
  consentChallenge,
  disableSignUp,
  error,
  flow,
  flowVersion,
  hideCopy,
  isCompactLayout = true,
  isFullView,
  locale,
  modalCustomized,
  popUpFeatures,
  popUpName,
  redirectType,
  redirectUri,
  requireVerification,
  responseMode,
  responseType,
  scope,
  shopId,
  state,
  transactionParams,
  uxMode,
}: BuildAuthorizeUrlParams) {
  const signUpEnabled =
    disableSignUp === undefined ? undefined : disableSignUp === false;

  /* eslint-disable @typescript-eslint/naming-convention */
  const baseParams: Record<string, string | undefined> = {
    analytics_context: analyticsContext,
    analytics_trace_id: analyticsTraceId,
    avoid_sdk_session: booleanQueryParam(avoidSdkSession),
    api_key: apiKey,
    checkout_redirect_url: checkoutRedirectUrl,
    checkout_token: checkoutToken,
    checkout_version: checkoutVersion,
    client_id: clientId,
    code_challenge: codeChallenge,
    code_challenge_method: codeChallengeMethod,
    compact_layout: booleanQueryParam(isCompactLayout),
    consent_challenge: booleanQueryParam(consentChallenge),
    'customize-modal': booleanQueryParam(modalCustomized),
    ...(error && {error}),
    flow: flow ? flow.toString() : undefined,
    flow_version: flowVersion,
    full_view: booleanQueryParam(isFullView),
    hide_copy: booleanQueryParam(hideCopy),
    locale,
    pop_up_features: redirectType === 'pop_up' ? popUpFeatures : undefined,
    pop_up_name: redirectType === 'pop_up' ? popUpName : undefined,
    preact: 'true',
    redirect_type: redirectType,
    redirect_uri: redirectUri || isoWindow.location.origin,
    require_verification: booleanQueryParam(requireVerification),
    response_mode: responseMode || 'web_message',
    response_type: responseType || 'id_token',
    scope: scope || 'openid email profile',
    sign_up_enabled: booleanQueryParam(signUpEnabled),
    shop_id: shopId ? shopId.toString() : undefined,
    state,
    target_origin: isoWindow.location.origin,
    transaction_params: transactionParams,
    ux_mode: uxMode,
  };
  /* eslint-enable @typescript-eslint/naming-convention */

  Object.keys(baseParams).forEach(
    (key) => baseParams[key] === undefined && delete baseParams[key],
  );

  return new URLSearchParams(baseParams as Record<string, string>);
}
